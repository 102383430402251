import React from 'react';

import Footer from '../components/Footer';
import InfoSection from '../components/InfoSection';
import {
  homeObjThree,
  homeObjFour,
  homeObjFive,
  homeObjSix,
  homeObjSeven,
  homeObjEight,
  homeObjNine,
  application,
} from '../components/InfoSection/Data';
import Services from '../components/Services/Services';
import About from '../components/About';
import Support from '../components/Support';
import Testimonials from '../components/Testimonios';
import HowItWorks from '../components/howWorks/HowWorks';
import HeroSection from '../components/HeroSection/HeroSection';

export default function Home() {
  return (
    <>
      <HeroSection />
      <InfoSection {...application} />
      <HowItWorks />
      <Services />
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjFour} />
      <InfoSection {...homeObjFive} />
      <InfoSection {...homeObjSix} />
      <InfoSection {...homeObjSeven} />
      <InfoSection {...homeObjEight} />
      <InfoSection {...homeObjNine} />
      <Testimonials />
      <About />
      <Support />
      <Footer />
    </>
  );
}
