import React, { useRef, useEffect, useState } from 'react';

const isSafari = () => {
  const ua = navigator.userAgent.toLocaleLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0;
};

const AutoplayVideo = ({ video }) => {
  const [shouldUseImage, setShouldUseImage] = useState(false);

  const videoParentRef = useRef();

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute('muted', '');
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();

          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = 'none';
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <img src={video} alt="muted video" />
  ) : (
    <div
      style={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0 }}
      dangerouslySetInnerHTML={{
        __html: `<video
          autoPlay
          loop
          muted
          playsinline
          style="height: 800px; width: 100%; object-fit: cover; opacity: 0.2;"
        >
          <source src="${video}" type="video/mp4" />
        </video>`,
      }}
    />
  );
};

export default AutoplayVideo;
