import React from 'react';
import 'aos/dist/aos.css';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import Icon1 from '../../images/funciones/reports.png';
import Icon2 from '../../images/funciones/remote.png';
import Icon3 from '../../images/funciones/collaborative.png';
import waveBg from '../../images/sections/wave-18.svg';

export default function Services() {
  return (
    <ServicesContainer id={'services'}>
      <ServicesH1 data-aos="fade-up" data-aos-duration="1000">
        <FormattedMessage id="functions.title" />
      </ServicesH1>
      <ServicesWrapper>
        <div data-aos="fade-up" data-aos-duration="1000">
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesTextContainer>
              <ServicesH2>
                <FormattedMessage id="functions.fnTitle" />
              </ServicesH2>
              <ServicesP>
                <FormattedMessage id="functions.fnDescription" />
              </ServicesP>
            </ServicesTextContainer>
          </ServicesCard>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000">
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesTextContainer>
              <ServicesH2>
                <FormattedMessage id="functions.fnTitleB" />
              </ServicesH2>
              <ServicesP>
                <FormattedMessage id="functions.fnDescriptionB" />
              </ServicesP>
            </ServicesTextContainer>
          </ServicesCard>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000">
          <ServicesCard>
            <ServicesIcon src={Icon3} />
            <ServicesTextContainer>
              <ServicesH2>
                <FormattedMessage id="functions.fnTitleC" />
              </ServicesH2>
              <ServicesP>
                <FormattedMessage id="functions.fnDescriptionC" />
              </ServicesP>
            </ServicesTextContainer>
          </ServicesCard>
        </div>
      </ServicesWrapper>
      <InfoBg2>
        <ImageBottomBg src={waveBg} alt={'waveBg'} />
      </InfoBg2>
    </ServicesContainer>
  );
}

const ServicesContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #192685;

  @media screen and (max-width: 1001px) {
    height: 1100px;
  }

  @media screen and (max-width: 550px) {
    height: 1600px;
  }
`;

const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 0 50px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 20px;
  }
`;

const ServicesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    margin-left: 24px;
  }

  @media screen and (max-width: 550px) {
    align-items: center;
    margin-left: 0px;
  }
`;

const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 100%;
  max-width: 300px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    max-width: 900px;
    max-height: 350px;
    margin-bottom: 24px;
    padding: 5px 30px 5px 30px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    max-width: 400px;
    max-height: 450px;
  }

  /* &:hover {
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
  } */
`;

const ServicesIcon = styled.img`
  height: 180px;
  max-width: 280px;
  margin-bottom: 10px;
  object-fit: contain;

  @media screen and (max-width: 550px) {
    /* height: 100px;
    width: 100px; */
  }
`;

const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 20px;
  color: dimgrey;

  @media screen and (max-width: 1024px) {
    text-align: left;
  }
`;

const InfoBg2 = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ whiteWaveTop }) =>
    whiteWaveTop
      ? 'linear-gradient(180deg, #ffffff 90%, #192685 10%)'
      : 'linear-gradient(180deg, #192685 10%, #ffffff 10%)'};
`;

const ImageBottomBg = styled.img`
  width: 110%;
  height: 100%;
  margin: 0 -10px -10px -10px;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;
