import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import section from '../images/sections/wave-6.svg';
import Footer2 from '../components/Footer2';

export default function Politica() {
  return (
    <>
      <Container>
        <HeroContainer>
          <HeroBg>
            <ImageBg src={section} alt={'sectionBackground'} />
          </HeroBg>
          <Title>
            <FormattedMessage id="politica" />
          </Title>
        </HeroContainer>

        <ContentWrapper>
          <ImportantWrapper>
            <Paragraph3>
              <FormattedMessage id="politica1" />
            </Paragraph3>
            <Paragraph4>
              <FormattedMessage id="politica2" />
            </Paragraph4>
          </ImportantWrapper>
          <Subtitle1 id="generalidades">
            <FormattedMessage id="politica3" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica4" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica5" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica6" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica7" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica8" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica9" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica10" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica11" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica12" />
          </Subtitle1>
          <Subtitle2>
            <FormattedMessage id="politica13" />
          </Subtitle2>
          <Paragraph2>
            <FormattedMessage id="politica14" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica15" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica16" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica17" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica18" />
          </Paragraph2>
          <Subtitle2>
            <FormattedMessage id="politica19" />
          </Subtitle2>
          <Paragraph2>
            <FormattedMessage id="politica20" />
          </Paragraph2>
          <Subtitle2>
            <FormattedMessage id="politica21" />
          </Subtitle2>
          <Paragraph2>
            <FormattedMessage id="politica22" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica23" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica24" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica25" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica26" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica27" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica28" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica29" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica30" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica31" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica32" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica33" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica34" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica35" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica36" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica37" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica38" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica39" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica40" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica41" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica42" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica43" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica44" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica45" />
          </Subtitle1>
          <Subtitle2>
            <FormattedMessage id="politica46" />
          </Subtitle2>
          <Paragraph2>
            <FormattedMessage id="politica47" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica48" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica49" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica50" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica51" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica52" />
          </Paragraph2>
          <Subtitle2>
            <FormattedMessage id="politica53" />
          </Subtitle2>
          <Paragraph2>
            <FormattedMessage id="politica54" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica55" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica56" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica57" />
          </Paragraph2>
          <Subtitle2>
            <FormattedMessage id="politica58" />
          </Subtitle2>
          <Paragraph2>
            • <FormattedMessage id="politica59" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica60" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica61" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica62" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica63" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica64" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica65" />
          </Paragraph2>
          <Paragraph2>
            • <FormattedMessage id="politica66" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica67" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica68" />
          </Paragraph2>
          <Paragraph2>
            <FormattedMessage id="politica69" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica70" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica71" />
          </Paragraph2>
          <Subtitle1>
            <FormattedMessage id="politica72" />
          </Subtitle1>
          <Paragraph2>
            <FormattedMessage id="politica73" />
          </Paragraph2>
          <Paragraph2>
            Copyright © {new Date().getFullYear()} Nupav. <FormattedMessage id="politica74" />
          </Paragraph2>
        </ContentWrapper>
      </Container>
      <Footer2 />
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #192685;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: '100%';
`;

export const HeroContainer = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 90%, #192685 100%);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 500px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 130%;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const Title = styled.h1`
  width: 100%;
  padding: 30px;
  color: grey;
  font-size: 48px;
  text-align: center;
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
  position: relative;
`;

export const ImportantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -100px;
  background: white;
  border-radius: 15px;
  margin-left: 100px;
  margin-right: 100px;
  padding: 20px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const Subtitle1 = styled.p`
  margin-top: 60px;
  color: white;
  font-size: 24px;
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Subtitle2 = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 20px;
  text-align: left;
  margin-left: 130px;
  margin-right: 100px;
  text-decoration-line: underline;

  @media screen and (max-width: 768px) {
    margin-left: 40px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Paragraph2 = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 18px;
  text-align: justify;
  margin-left: 130px;
  margin-right: 100px;

  @media screen and (max-width: 768px) {
    margin-left: 40px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const Paragraph3 = styled.p`
  color: grey;
  font-size: 15px;
  text-align: left;
`;

export const Paragraph4 = styled.p`
  margin-top: 24px;
  color: grey;
  font-size: 15px;
  text-align: justify;
`;
