import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Footer from '../components/Footer';

import topWave from '../images/footer/wave-3.svg';

export default function Calendar() {
  return (
    <>
      <Container>
        <HeaderContainer>
          <Title>
            <h1 data-aos="fade-left" data-aos-duration="1000">
              <FormattedMessage id="calendar.title" />
            </h1>
          </Title>
          <SubTitle>
            <p data-aos="fade-left" data-aos-duration="2000">
              <FormattedMessage id="calendar.subtitle" />.
            </p>
          </SubTitle>
        </HeaderContainer>
        <Iframe>
          <div>
            <img src={topWave} alt="topwave" />
          </div>
          <iframe
            data-aos="fade-up"
            title="hubspot"
            style={{
              width: '100%',
              height: '900px',
              border: 'none',
              minHeight: '516px',
              borderRadius: '8px',
              padding: '0 25px',
            }}
            src="https://meetings.hubspot.com/mirko9/presentacion?embed=false"
          />
        </Iframe>
      </Container>
      <Footer bgcolor={'#f5f8fa'} />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f8fa;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #192685;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
  max-width: 1024px;
  padding: 40px;

  > h1 {
    font-size: 48px;
    color: white;

    @media screen and (max-width: 480px) {
      font-size: 32px;
    }
  }
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
  padding: 0 40px 50px 40px;

  > p {
    font-size: 24px;
    color: white;
    font-weight: 300;

    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
  }
`;

const Iframe = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* min-width: 600px; */
  padding-top: 300px;
  overflow: hidden;

  @media screen and (max-width: 1700px) {
    padding-top: 200px;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 150px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 100px;
  }

  > div:nth-child(1) {
    position: absolute;
    top: -8px;
    left: -5px;
    right: -5px;
    z-index: 2;
    /* background-color: white; */
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
