import React from 'react';
import styled from 'styled-components';

import Footer2 from '../components/Footer2';
import { FormattedMessage, useIntl } from 'react-intl';

export default function Solutions() {
  const intl = useIntl();
  const Card = ({ title, url, urlView, subtitle }) => (
    <CardContainer onClick={() => window.open(url)}>
      <span>{title}</span>
      <div>
        <div>
          <span>{urlView}</span>
        </div>
        <span>{subtitle}.</span>
      </div>
    </CardContainer>
  );
  return (
    <>
      <Container>
        <span>
          <FormattedMessage id="acceso.sectionA" />
        </span>
        <Card
          title={intl.formatMessage({ id: 'acceso.titleA' })}
          url={'https://cloud.nupav.com'}
          urlView={'cloud.nupav.com'}
          subtitle={intl.formatMessage({ id: 'acceso.subtitleA' })}
        />
        <span>
          <FormattedMessage id="acceso.sectionB" />
        </span>
        <Card
          title={intl.formatMessage({ id: 'acceso.titleB' })}
          url={'https://licitaciones.nupav.com'}
          urlView={'licitaciones.nupav.com'}
          subtitle={intl.formatMessage({ id: 'acceso.subtitleB' })}
        />
        <Card
          title={intl.formatMessage({ id: 'acceso.titleC' })}
          url={'https://proveedores.nupav.com'}
          urlView={'proveedores.nupav.com'}
          subtitle={intl.formatMessage({ id: 'acceso.subtitleC' })}
        />
      </Container>
      <Footer2 />
    </>
  );
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #192685;
  padding-top: 200px;
  flex-direction: column;
  padding: 100px 40px 10px 40px;

  > span {
    font-size: 30px;
    color: white;
    font-weight: 400;
    margin: 40px 20px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    transition: all 0.2s ease-in-out;
    scale: 1.02;
  }

  > span {
    font-size: 18px;
    color: white;
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    align-items: flex-start;
    max-width: 700px;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }

    > div {
      border-radius: 8px;
      background-color: white;
      padding: 25px;
    }
    > span {
      margin-left: 20px;
      font-size: 16px;
      color: white;
      font-weight: 300;

      @media screen and (max-width: 500px) {
        margin-left: 0px;
        margin-top: 20px;
      }
    }
  }
`;
