import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: #192685;
`;

export const FooterWrap = styled.div`
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  /* background-color: red; */
  padding-bottom: 30px;
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  font-size: 15px;
  margin-bottom: 16px;
`;

export const ImgWrap = styled.div`
  max-width: 200px;
  height: 100%;
  margin-left: --10px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const NavBtnLink = styled(Link)`
  white-space: nowrap;
  color: #ffffff;
  margin-bottom: 16px;
  font-size: 15px;

  @media screen and (max-width: 820px) {
    margin-bottom: 20px;
  }
`;

export const InfoBg2 = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ whiteWaveTop, bgcolor }) =>
    !whiteWaveTop
      ? `linear-gradient(180deg, ${bgcolor || '#ffffff'} 90%, #192685 10%)`
      : 'linear-gradient(180deg, #192685 10%, #ffffff 10%)'};
`;

export const ImageBg = styled.img`
  width: 110%;
  height: 100%;
  margin: 0 -10px -10px -10px;
  object-fit: cover;
`;
