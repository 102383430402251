import info3 from '../../images/sections/Permit1.png';
import info4 from '../../images/sections/integration.png';
import info5 from '../../images/sections/validity.png';
import info6 from '../../images/sections/files.png';
import info7 from '../../images/sections/traz.png';
import info8 from '../../images/sections/chat.png';
import info9 from '../../images/sections/database.png';

import appstorelogo from '../../images/sections/appstore.png';
import googleplaylogo from '../../images/sections/googleplay.png';
import applicationTopWave from '../../images/sections/wave-3.svg';
import applicationBottomWave from '../../images/sections/wave-15.svg';
import recolectaBottomWave from '../../images/sections/wave-17.svg';
import app1 from '../../images/sections/app-screens.png';

export const homeObjThree = {
  id: 'features',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Seguimiento',
  topLineEn: 'Follow-up',
  headline: 'Programa de hitos y cumplimientos',
  headlineEn: 'Milestones and compliance program',
  description:
    'Contamos con gran cantidad de programas secuenciales de hitos pre-establecidos para el seguimiento y control de los procesos críticos de tu proyecto de construcción. El cumplimiento va en estrecha relación con los controles que se deben realizar en cada proceso desde la aprobación del proyecto para construcción hasta el término y entrega del Certificado.',
  descriptionEn:
    'We have a large number of sequential programs of pre-established milestones for the monitoring and control of the critical processes of your construction project. Compliance is closely related to the controls that must be carried out in each process from the approval of the project for construction to the completion and delivery of the Certificate.',
  buttonLabel: 'Siguiente',
  buttonLabelEn: 'Next',
  imgStart: false,
  img: info3,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  showTitle: true,
  shortPage: true,
  showButton: true,
  goTo: 'datos',
  largeImage: true,
};

export const homeObjFour = {
  id: 'datos',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Datos',
  topLineEn: 'Data',
  headline: 'Integración de datos',
  headlineEn: 'Data integration',
  description:
    'Nos integramos a tus herramientas tecnológicas para extraer los datos y preparar los informes de seguimientos de forma automatizada. Además contamos con integración a visualizadores que permiten en cosa de clicks subir tus archivos CAD como planos y modelos 3D, fotos y videos 360, ortofotos y fotogrametría de imagenes capturadas con cámaras tradicionales o DRONES. De esta manera, tus equipos pueden colaborar sin tener que salir de nuestra plataforma.',
  descriptionEn:
    'We integrate with your technological tools to extract data and prepare monitoring reports in an automated manner. We also have integration with viewers that allow you to upload your CAD files such as plans and 3D models, 360 photos and videos, orthophotos and photogrammetry of images captured with traditional cameras or DRONES in just a few clicks. This way, your teams can collaborate without having to leave our platform.',
  buttonLabel: 'Siguiente',
  buttonLabelEn: 'Next',
  imgStart: true,
  img: info4,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'vigencia',
  largeImage: true,
};

export const homeObjFive = {
  id: 'vigencia',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Vigencia',
  topLineEn: 'Validity',
  headline: 'Inteligencia de vigencia documental',
  headlineEn: 'Documentary validity intelligence',
  description:
    'Informa y notifica las fechas de vencimiento de los archivos incorporados al repositorio documental, generando alertas de vencimiento en permisos municipales, vencimientos de boletas de garantías, entre otras actividades que dispongan tiempos críticos de y para su cumplimiento.',
  descriptionEn:
    'Informs and notifies the expiration dates of the files incorporated into the documentary repository, generating expiration alerts for municipal permits, expiration dates for warranty receipts, among other activities that require critical times for and for compliance.',
  buttonLabel: 'Siguiente',
  buttonLabelEn: 'Next',
  imgStart: false,
  img: info5,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'biblioteca',
  largeImage: true,
};

export const homeObjSix = {
  id: 'biblioteca',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Biblioteca',
  topLineEn: 'Library',
  headline: 'Repositorio documental',
  headlineEn: 'Document repository',
  description:
    'Planos y Documentos técnicos-administrativos con la versión aprobada por cada organismo para su desarrollo y contrucción. Disponibiliza de toda la información según la normativa vigente (manuales, formularios, check list, fichas, detalles contructivos, entre otros…) para un completo respaldo estés donde estés.',
  descriptionEn:
    'Technical-administrative plans and documents with the version approved by each organization for its development and construction. It makes all the information available according to current regulations (manuals, forms, check lists, sheets, construction details, among others...) for complete support wherever you are.',
  buttonLabel: 'Siguiente',
  buttonLabelEn: 'Next',
  imgStart: true,
  img: info6,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'trazabilidad',
  largeImage: true,
};

export const homeObjSeven = {
  id: 'trazabilidad',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Trazabilidad',
  topLineEn: 'Traceability',
  headline: 'Trazabilidad a los registros de obra',
  headlineEn: 'Traceability to construction records',
  description:
    'En tiempo real podrás conocer el estado de avance de la obra y las gestiones complementarias que se han realizado y las que se deben realizar con el fin de mantener el conocimiento según avanzan los trabajos, optimizando el conocimiento de la información en el tiempo.',
  descriptionEn:
    'In real time you will be able to know the progress status of the work and the complementary procedures that have been carried out and those that must be carried out in order to maintain knowledge as the work progresses, optimizing the knowledge of the information over time.',
  buttonLabel: 'Siguiente',
  buttonLabelEn: 'Next',
  imgStart: false,
  img: info7,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'asesoria',
  largeImage: true,
};

export const homeObjEight = {
  id: 'asesoria',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Customer Success',
  topLineEn: 'Customer Success',
  headline: 'Asistencia online permanente',
  headlineEn: 'Permanent online assistance',
  description:
    'Con un equipo dedicado y altamente capacitado en el uso de la herramienta, te brindamos apoyo en todo momento para que puedas sacarle el máximo provecho a los datos, informes y plataforma de colaboración.',
  descriptionEn:
    'With a dedicated team highly trained in the use of the tool, we provide you with support at all times so that you can get the most out of the data, reports and collaboration platform.',
  buttonLabel: 'Siguiente',
  buttonLabelEn: 'Next',
  imgStart: true,
  img: info8,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'basedatos',
  largeImage: true,
};

export const homeObjNine = {
  id: 'basedatos',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Base de Datos',
  topLineEn: 'Database',
  headline: 'Gran base de datos para apoyar tu gestión',
  headlineEn: 'Large database to support your management',
  description:
    'Entrega una completa base de datos relacionada a organismos publicos, entidades reguladoras, laboratorios, proyectistas, contratistas y proveedores críticos.',
  descriptionEn:
    'Provides a complete database related to public organizations, regulatory entities, laboratories, designers, contractors and critical suppliers.',
  buttonLabel: 'Registrate Ahora',
  buttonLabelEn: 'Register now',
  imgStart: false,
  img: info9,
  alt: 'Papeers',
  dark: false,
  primary: false,
  darkText: true,
  shortPage: true,
  showButton: true,
  goTo: 'home',
  bottomWave: true,
  img4: recolectaBottomWave,
  alt4: 'background2',
  whiteWaveTop: true,
  largeImage: true,
};

export const application = {
  id: 'aplicacion',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Visibilidad, Accesibilidad y Trazabildiad',
  topLineEn: 'Visibility, Accessibility and Traceability',
  headline: 'Datos en tiempo real',
  headlineEn: 'Real time data',
  description:
    'Las gestiones y decisiones de obras no pueden esperar, Nupav mantiene en línea y de manera trazable toda la información relevante de tu proyecto para que puedas acceder desde cualquier lugar sin necesidad recurrir a otros software, transformandose así, en tu primera fuente de consulta de información.',
  descriptionEn:
    'Work management and decisions cannot wait, Nupav keeps all the relevant information of your project online and traceable so that you can access it from anywhere without having to resort to other software, thus becoming your first source of information consultation.',
  buttonLabel: 'Conoce Más',
  buttonLabelEn: 'Learn More',
  imgStart: true,
  img: app1,
  alt: 'app',
  img1: appstorelogo,
  alt1: 'appstorelogo',
  img2: googleplaylogo,
  alt2: 'googleplaylogo',
  img3: applicationTopWave,
  alt3: 'background',
  img4: applicationBottomWave,
  alt4: 'background2',
  dark: false,
  primary: false,
  darkText: false,
  showButton: false,
  showAppStoreButton: true,
  goTo: 'services',
  shortPage: true,
  topWave: false,
  bottomWave: true,
  largeImage: true,
  whiteWaveTop: false,
};
