import React from 'react';
import 'aos/dist/aos.css';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../ButtonElement';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  Img,
  Img1,
  ImgWrap,
  ImgWrap1,
  LogoWrapper,
  InfoBg,
  ImageTopBg,
  ImageBottomBg,
  InfoBg2,
  BtnWrap,
  TitleWrapper,
  TitleH1,
} from './InfoElements';

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  topLineEn,
  lightText,
  headline,
  headlineEn,
  darkText,
  description,
  descriptionEn,
  img,
  alt,
  shortPage,
  img1,
  alt1,
  img2,
  alt2,
  img3,
  alt3,
  img4,
  alt4,
  topWave,
  bottomWave,
  showButton,
  showAppStoreButton,
  primary,
  dark,
  dark2,
  showTitle,
  goTo,
  buttonLabel,
  buttonLabelEn,
  whiteWaveTop,
  largeImage,
}) => {
  const intl = useIntl();
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper shortPage={shortPage}>
          {/* {topWave && (
            <InfoBg>
              <ImageTopBg src={img3} alt={alt3} />
            </InfoBg>
          )} */}
          {showTitle && (
            <TitleWrapper>
              <TitleH1>
                <FormattedMessage id="infoSection.title" />
              </TitleH1>
            </TitleWrapper>
          )}
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine data-aos={'fade-up'} data-aos-duration="1000" lightText={lightText}>
                  {intl.locale === 'es' ? topLine : topLineEn}
                </TopLine>
                <Heading data-aos={'fade-up'} data-aos-duration="1500" lightText={lightText}>
                  {intl.locale === 'es' ? headline : headlineEn}
                </Heading>
                <Subtitle data-aos={'fade-up'} data-aos-duration="2000" darkText={darkText}>
                  {intl.locale === 'es' ? description : descriptionEn}
                </Subtitle>
                {showButton && (
                  <BtnWrap data-aos="fade-up">
                    <Button
                      to={goTo}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                    >
                      {intl.locale === 'es' ? buttonLabel : buttonLabelEn}
                    </Button>
                  </BtnWrap>
                )}
                {/* {showAppStoreButton && (
                  <LogoWrapper data-aos="fade-up">
                    <ImgWrap1>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://apps.apple.com/cl/app/nupav/id1548381855"
                      >
                        <Img1 src={img1} alt={alt1} />
                      </a>
                    </ImgWrap1>
                    <ImgWrap1>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.nuevaurbana.nupav"
                      >
                        <Img1 src={img2} alt={alt2} />
                      </a>
                    </ImgWrap1>
                  </LogoWrapper>
                )} */}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap data-aos="fade-up" data-aos-delay="300" largeImage={largeImage}>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
        {bottomWave && (
          <InfoBg2 whiteWaveTop={whiteWaveTop}>
            <ImageBottomBg src={img4} alt={alt4} />
          </InfoBg2>
        )}
      </InfoContainer>
    </>
  );
};

export default InfoSection;
