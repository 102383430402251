import styled from 'styled-components';

export const ServicesContainer = styled.div`
  /* height: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;

  /* @media screen and (max-width: 1024px) {
    height: 1300px;
  }

  @media screen and (max-width: 768px) {
    height: 1600px;
  }

  @media screen and (max-width: 480px) {
    height: 2300px;
  } */
`;

export const ServicesWrapper = styled.div`
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 50px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100px;
  padding: 30px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  width: 150px;
  height: 100px;
  object-fit: contain;
`;

export const ServicesIcon2 = styled.img`
  width: 250px;
  height: 230px;
  object-fit: contain;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: rgba(77, 80, 85, 1);
  margin-bottom: 64px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 2.5rem;
  color: rgba(77, 80, 85, 1);
  margin: 120px 0 64px 0;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;

export const InfoBg2 = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ whiteWaveTop }) =>
    whiteWaveTop
      ? 'linear-gradient(180deg, #ffffff 90%, #192685 10%)'
      : 'linear-gradient(180deg, #192685 10%, #ffffff 10%)'};
`;

export const ImageBg = styled.img`
  width: 110%;
  height: 100%;
  margin: 0 -10px -10px -10px;
  object-fit: cover;
`;
