import React from 'react';
import 'aos/dist/aos.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import topWave from '../../images/sections/wave-15.svg';
import Icon1 from '../../images/support/cchc.png';
import Icon2 from '../../images/support/conalza.png';
import Icon3 from '../../images/support/corso.png';
import Icon4 from '../../images/support/delabase.png';
import Icon5 from '../../images/support/dls.png';
import Icon6 from '../../images/support/ei.png';
import Icon7 from '../../images/support/galilea.png';
import Icon8 from '../../images/support/iandes.png';
import Icon9 from '../../images/support/imagine.png';
import Icon10 from '../../images/support/inarco.png';
import Icon11 from '../../images/support/minvu.png';
import Icon12 from '../../images/support/mop.png';
import Icon13 from '../../images/support/patio.png';
import Icon14 from '../../images/support/territoria.png';
import Icon15 from '../../images/support/svg-cloud-logo.svg';
import Icon16 from '../../images/support/emprelatam.png';
import Icon17 from '../../images/support/discovery.png';
import Icon18 from '../../images/support/aceleralatam.png';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesIcon2,
  ServicesH2,
  InfoBg2,
  ImageBg,
} from './SupportElements';

export default function Support() {
  const WithStyles = ({ description, headline, image }) => (
    <WithStylesContainer>
      <img src={image} alt="" />
      {/* <span>{headline}</span>
      <span>{description}</span> */}
    </WithStylesContainer>
  );
  return (
    <ServicesContainer id={'support'}>
      <InfoBg2>
        <ImageBg src={topWave} alt={'footer'} />
      </InfoBg2>
      <ServicesH1 data-aos="fade-up">
        <FormattedMessage id="support.title" />
      </ServicesH1>
      <ServicesWrapper>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon2} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon3} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon4} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon5} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon6} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon7} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon8} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon10} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon13} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon14} />
        </ServicesCard>
      </ServicesWrapper>
      <ServicesH2 data-aos="fade-up">
        <FormattedMessage id="support.titleB" />
      </ServicesH2>
      <ServicesWrapper>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon11} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon src={Icon12} />
        </ServicesCard>
      </ServicesWrapper>
      <ServicesH2 data-aos="fade-up">
        <FormattedMessage id="support.titleC" />
      </ServicesH2>
      <ServicesWrapper>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon2 src={Icon9} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon2 src={Icon15} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon2 src={Icon17} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon2 src={Icon18} />
        </ServicesCard>
        <ServicesCard data-aos="fade-up">
          <ServicesIcon2 src={Icon16} />
        </ServicesCard>
      </ServicesWrapper>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay
        autoPlaySpeed={1}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        customTransition="all 1s linear"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={2}
        swipeable
        transitionDuration={1000}
      >
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 1"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="Appending currency sign to a purchase form in your e-commerce site using plain JavaScript."
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 2"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="Appending currency sign to a purchase form in your e-commerce site using plain JavaScript."
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 1"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550338861-b7cfeaf8ffd8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 2"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550223640-23097fc71cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 2"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 2"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550330039-a54e15ed9d33?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 2"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1549737328-8b9f3252b927?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="React Carousel with Server Side Rendering Support – Part 1"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="Fixing CSS load order/style.chunk.css incorrect in Nextjs"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
        <WithStyles
          description="Fixing CSS load order/style.chunk.css incorrect in Nextjs"
          headline="w3js.com - web front-end studio"
          image="https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        />
      </Carousel>
    </ServicesContainer>
  );
}

const WithStylesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  background-color: red;

  > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
`;
