import testimonio1 from '../../images/support/ei.png';
import testimonio2 from '../../images/support/eurocorp.png';
import testimonio3 from '../../images/support/patio.png';

export const testimoniosData = [
  {
    id: 1,
    job: 'Gerente Técnico',
    jobEn: 'Technical manager',
    company: 'Echeverría Izquierdo Edificaciones',
    title: 'Tito Fuentes',
    text: 'El uso de la plataforma nos ha permitido sistematizar de mejor forma la información y gestionar de una manera ordenada el proceso completo, además de ser una guía muy útil para controlar los tiempos de revisión y respuesta de las instituciones públicas y subcontratistas a quienes les encargamos la ejecución de estos trabajos.',
    textEn:
      'The use of the platform has allowed us to better systematize the information and manage the entire process in an orderly manner, in addition to being a very useful guide to control the review and response times of the public institutions and subcontractors to whom we entrust the execution of these works.',
    image: testimonio1,
  },
  {
    id: 2,
    job: 'Jefe de Proyectos',
    jobEn: 'Project Manager',
    company: 'EuroCorp Inmobiliaria',
    title: 'Felipe Guerra',
    text: 'La intervención de Nupav en etapas tempranas puede atacar riesgos derivados de fallas en ingeniería, estrategias de sectorización con miras a un proceso de recepción adecuado a las necesidades del proyecto, entre otros aportes de valor. Durante la Construcción, su intervención permite al mandante contar con información de terreno actualizada para llevar un seguimiento cercano del contratista con el fin de atacar desviaciones a la planificación; y contar con su  apoyo en la gestión contractual y de gerenciamiento de la construcción de las medidas de mitigación.',
    textEn:
      "Nupav's intervention in early stages can attack risks derived from engineering failures, sectorization strategies with a view to a reception process appropriate to the needs of the project, among other value contributions. During Construction, its intervention allows the principal to have updated terrain information to closely monitor the contractor in order to attack deviations from the planning; and have their support in the contractual management and construction management of the mitigation measures.",
    image: testimonio2,
  },
  {
    id: 3,
    job: 'Gerente de Construcción',
    jobEn: 'Construction manager',
    company: 'Grupo Patio',
    title: 'Marcelo Esquivel',
    text: 'Lo mas destacable es la actualización diaria del sistema, y que si es necesario solicitar una asesoría mas profunda, en la búsqueda de poder acotar los posibles impactos que alguna actividad pueda generar, siempre existe una respuesta oportuna y lógica, en la búsqueda de la eficiencia del proyecto, tanto en costos como en plazos y con ello de manera conjunta resolver de manera exitosa.',
    textEn:
      'The most notable thing is the daily updating of the system, and that if it is necessary to request more in-depth advice, in the search of being able to limit the possible impacts that some activity may generate, there is always a timely and logical response, in the search for efficiency. of the project, both in costs and deadlines and thereby jointly resolve it successfully.',
    image: testimonio3,
  },
];
