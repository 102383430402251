import { Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';

import closeButton from '../../images/close-button.png';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ProductModal({ open, onClose, module }) {
  const history = useHistory();
  const intl = useIntl();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableEnforceFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container>
        <CloseContainer onClick={onClose}>
          <img src={closeButton} alt="close" />
        </CloseContainer>
        <ImageContainer>
          <img src={module.image} alt="" />
        </ImageContainer>
        <TitleContainer>
          <h1>{module.name}</h1>
        </TitleContainer>
        <DescriptionContainer>
          {(module.description || []).map((x) => (
            <p>{x}</p>
          ))}
        </DescriptionContainer>
        <ActionButton>
          <div
            onClick={() => {
              history.push('/calendar');
              scroll.scrollToTop();
            }}
          >
            <span>
              {module.build ? (
                <FormattedMessage id="product.modalA" />
              ) : (
                `${intl.formatMessage({ id: 'product.modalB' })}!`
              )}
            </span>
          </div>
        </ActionButton>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: ${(props) => (props.width ? props.width : '70%')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '800px')};
  border-radius: 8px;
  overflow-y: auto;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '70%')};
  outline: 0;
  padding: 30px 20px 20px 20px;

  @media (max-width: 500px) {
    width: 90%;
    max-height: 80%;
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  > img {
    width: 25px;
    height: 25px;

    :hover {
      scale: 1.1;
      transition: all 0.2s ease-in-out;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  > h1 {
    color: rgba(77, 80, 85, 1);
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px 20px;
  > p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 15px;
  }
`;

const ActionButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  > div {
    background-color: orange;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;

    > span {
      color: white;
    }
  }
`;
