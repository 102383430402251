import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  ImgWrap,
  Img,
  InfoBg2,
  ImageBg,
  NavBtnLink,
} from './FooterElements';

import footer from '../../images/footer/wave-19.svg';
import logoWhite from '../../images/logo/LogoWhite.png';
import logoAzul from '../../images/logo/LogoAzul.png';

const Footer = ({ bgcolor }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <InfoBg2 bgcolor={bgcolor}>
        <ImageBg src={footer} alt={'footer'} />
      </InfoBg2>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <ImgWrap>
                <Img src={logoWhite} alt={'logoWhite'} />
              </ImgWrap>
            </SocialLogo>
            <WebsiteRights>Nupav @ {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <NavBtnLink to="/politica-de-privacidad" onClick={toggleHome}>
              <FormattedMessage id="politica" />
            </NavBtnLink>
            <WebsiteRights>contacto@nupav.com</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
