import React from 'react';
import 'aos/dist/aos.css';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import recolectaBottomWave from '../../images/sections/wave-17.svg';
import flujo_nupav from '../../images/flujo_nupav.png';
import flujo_nupav_vertical from '../../images/flujo_nupav_vertical.png';
import flujo_nupavEn from '../../images/flujo_nupavEn.png';
import flujo_nupav_verticalEn from '../../images/flujo_nupav_verticalEn.png';
import useWindowDimensions from '../../helpers/windowDimensions';

export default function HowItWorks() {
  const intl = useIntl();
  const { width } = useWindowDimensions();
  return (
    <>
      <Container id="howitworks" lightBg={true}>
        <TitleContainer>
          <h1>
            <FormattedMessage id="howitworks.title" />
          </h1>
        </TitleContainer>
        <InfoRow>
          <div data-aos="fade-up" data-aos-delay="100">
            {width > 700 ? (
              <img src={intl.locale === 'es' ? flujo_nupav : flujo_nupavEn} alt={''} />
            ) : (
              <img
                src={intl.locale === 'es' ? flujo_nupav_vertical : flujo_nupav_verticalEn}
                alt={''}
              />
            )}
          </div>
        </InfoRow>

        <InfoBg2 whiteWaveTop={true}>
          <ImageBottomBg src={recolectaBottomWave} alt={''} />
        </InfoBg2>
      </Container>
    </>
  );
}

export const Container = styled.div`
  color: #ffffff;
  background: ${({ lightBg }) => (lightBg ? '#ffffff' : '#192685')};

  @media screen and (max-width: 768px) {
    padding: 100px 0 0 0;
  }
`;

export const TitleContainer = styled.div`
  display: grid;
  z-index: 1;
  /* height: 100px; */
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 24px 24px;
  justify-content: center;

  > h1 {
    font-size: 2.5rem;
    color: grey;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 480px) {
      font-size: 2rem;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-bottom: 60px;
    }
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;

  > div {
    max-width: 1100px;
    margin-bottom: 30px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 20px;
  }
`;

// WAVES
export const InfoBg = styled.div`
  width: 101%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(128, 181, 181, 1) 1%, rgba(255, 255, 255, 1) 1%);
`;

export const ImageTopBg = styled.img`
  width: 101%;
  height: 100%;
  margin-top: -10px;
`;

export const InfoBg2 = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({ whiteWaveTop }) =>
    whiteWaveTop
      ? 'linear-gradient(180deg, #ffffff 90%, #192685 10%)'
      : 'linear-gradient(180deg, #192685 10%, #ffffff 10%)'};
`;

export const ImageBottomBg = styled.img`
  width: 110%;
  height: 100%;
  margin: 0 -10px -10px -10px;
  object-fit: cover;
`;
