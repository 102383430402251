import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isHome = !!pathname.split('/')[1] === false;
  const isPolicy = pathname.split('/')[1] === 'politica-de-privacidad';
  const isCalendar = pathname.split('/')[1] === 'calendar';
  const isProduct = pathname.split('/')[1] === 'product';

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            onClick={() => {
              toggle();
              history.push('/');
              window.scrollTo(0, 0);
            }}
          >
            <FormattedMessage id="sidebar.home" />
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              toggle();
              history.push('/product');
              window.scrollTo(0, 0);
            }}
          >
            <FormattedMessage id="navbar.navA" />
          </SidebarLink>
          <SidebarLink
            onClick={() => {
              toggle();
              history.push('/calendar');
              window.scrollTo(0, 0);
            }}
          >
            <FormattedMessage id="navbar.navB" />
          </SidebarLink>
          {/* {isHome && (
            <SidebarLink to="features" onClick={toggle}>
              <FormattedMessage id="navbar.navC" />
            </SidebarLink>
          )} */}
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute
            onClick={() => {
              history.push('/access');
              scroll.scrollToTop();
            }}
          >
            <FormattedMessage id="navbar.signin" />
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
