import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons/lib';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { Link as LinkR, useLocation, useHistory } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { FormattedMessage } from 'react-intl';

import logoAzul from '../../images/logo/LogoAzul.png';
import logoBlanco from '../../images/logo/LogoBlanco2.png';

export default function Navbar({ toggle, locale, setLocale }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const [scrollNav, setScrollNav] = useState(false);

  const bgColor = pathname.split('/')[1] === 'calendar' ? 'white' : 'transparent';
  const isHome = !!pathname.split('/')[1] === false;
  const isPolicy = pathname.split('/')[1] === 'politica-de-privacidad';
  const isCalendar = pathname.split('/')[1] === 'calendar';
  const isAcceso = pathname.split('/')[1] === 'access';
  const isProduct = pathname.split('/')[1] === 'product';

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    history.push('/');
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#ffff' }}>
        <Container scrollNav={scrollNav} bgColor={bgColor} isHome={isHome}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} scrollNav={scrollNav}>
              <ImgWrap>
                <Img
                  src={(isHome || isPolicy || isProduct) && !scrollNav ? logoAzul : logoBlanco}
                  alt={'logo'}
                />
              </ImgWrap>
            </NavLogo>

            {isHome && (
              <NavMenu>
                <NavItem>
                  <News
                    onClick={() => {
                      history.push('/product');
                      scroll.scrollToTop();
                    }}
                    scrollNav={scrollNav}
                  >
                    <FormattedMessage id="navbar.navA" />
                  </News>
                </NavItem>
                <NavItem>
                  <News
                    onClick={() => {
                      history.push('/calendar');
                      scroll.scrollToTop();
                    }}
                    scrollNav={scrollNav}
                  >
                    <FormattedMessage id="navbar.navB" />
                  </News>
                </NavItem>
                {/* <NavItem>
                  <NavLinks
                    to="features"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    scrollNav={scrollNav}
                  >
                    <FormattedMessage id="navbar.navC" />
                  </NavLinks>
                </NavItem> */}
                <NavItem>
                  <News id="support" scrollNav={scrollNav}>
                    <FormattedMessage id="navbar.navD" />
                  </News>
                </NavItem>
              </NavMenu>
            )}

            <RightContainer scrollNav={scrollNav} isCalendar={isCalendar}>
              {!isAcceso && (
                <button
                  onClick={() => {
                    history.push('/access');
                    scroll.scrollToTop();
                  }}
                >
                  <FormattedMessage id="navbar.signin" />
                </button>
              )}
              <div onClick={() => setLocale(locale === 'es' ? 'en' : 'es')}>{locale}</div>
            </RightContainer>

            <RightContainerMobile scrollNav={scrollNav}>
              <div onClick={() => setLocale(locale === 'es' ? 'en' : 'es')}>{locale}</div>
              <div onClick={toggle}>
                <FaBars color={scrollNav || isAcceso || isCalendar ? 'white' : '#192685'} />
              </div>
            </RightContainerMobile>
          </NavbarContainer>
        </Container>
      </IconContext.Provider>
    </>
  );
}

const Container = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? '#192685' : 'transparent')};
  height: 65px;
  margin-top: -65px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
  /* background-color: green; */
  transition: 0.8s all ease;

  @media screen and (max-width: 600px) {
    height: 55px;
    margin-top: -55px;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 65px;
  z-index: 1;
  width: 100%;
  @media screen and (max-width: 600px) {
  }
`;

const NavLogo = styled(LinkR)`
  color: ${({ scrollNav }) => (scrollNav ? '#192685' : '#7fb5b5')};
  font-size: ${({ scrollNav }) => (scrollNav ? '2.5rem' : '2.5rem')};
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  @media screen and (max-width: 600px) {
    margin-left: 10px;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  height: 80px;
`;

const NavLinks = styled(LinkS)`
  color: ${({ scrollNav }) => (scrollNav ? 'white' : '#192685')};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  > button {
    border-radius: 50px;
    background-color: ${({ scrollNav, isCalendar }) =>
      scrollNav || isCalendar ? 'white' : '#192685'};
    white-space: nowrap;
    padding: 7px 12px;
    color: ${({ scrollNav, isCalendar }) => (scrollNav || isCalendar ? 'dimgrey' : '#ffffff')};
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: lightgrey;
      color: #010606;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background-color: grey;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;

    :hover {
      background-color: lightgrey;
      color: dimgrey;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const RightContainerMobile = styled.div`
  display: none;
  position: relative;
  align-items: center;

  > div:nth-child(1) {
    display: block;
    position: absolute;
    top: 12px;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: grey;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
  }

  > div:nth-child(2) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.4rem;
    cursor: pointer;
    color: ${({ scrollNav }) => (scrollNav ? '#192685' : 'rgba(77, 80, 85, 1)')};
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const ImgWrap = styled.div`
  /* background-color: red; */
  max-width: 180px;
  height: 100%;
  /* margin-left: --10px; */
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    margin-top: -10px;
    max-width: 150px;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    object-fit: contain;
  }

  @media screen and (max-width: 500px) {
    object-fit: contain;
  }
`;

const News = styled.a`
  color: ${({ scrollNav }) => (scrollNav ? 'white' : '#192685')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 5px 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #192685;
  }
`;
