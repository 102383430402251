import styled from 'styled-components';

export const ServicesContainer = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #192685;
  padding: 50px 100px 50px 100px;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const ServicesWrapper = styled.div`
  margin: 0 auto;
  align-items: center;
  padding: 0 10px;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 5px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 500px;
  margin: 40px 0px;
  padding: 20px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 500px) {
    height: 600px;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  width: 300px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    height: 80px;
    width: 200px;
  }

  @media screen and (max-width: 350px) {
    height: 70px;
    width: 150px;
  }
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1.3rem;
`;

export const ServicesH3 = styled.h3`
  font-size: 0.8rem;
  margin-bottom: 20px;
  color: darkgrey;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: justify;
  margin: 0px 10px 20px 10px;
  font-style: italic;
  font-weight: 300;
`;

export const InfoBg = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(128, 181, 181, 1) 1%, rgba(255, 255, 255, 1) 1%);
`;

export const ImageTopBg = styled.img`
  width: 101%;
  height: 100%;
  margin-top: -10px;
`;
