import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import Aos from 'aos';
import 'aos/dist/aos.css';
import VisibilitySensor from 'react-visibility-sensor';
import { FormattedMessage, useIntl } from 'react-intl';

import topWave from '../../images/sections/wave-5.svg';
import {
  ServicesContainer,
  ServicesH1,
  InfoBg2,
  ImageBg,
  ContentWrapper,
  NumberContainer,
  Number,
  Title,
} from './AboutElements';

const About = () => {
  return (
    <ServicesContainer id={'support'}>
      {/* <InfoBg2>
        <ImageBg src={topWave} alt={'footer'} />
      </InfoBg2> */}
      <ServicesH1 data-aos="fade-up">
        <FormattedMessage id="about.title" />
      </ServicesH1>
      <ContentWrapper>
        <NumberContainer data-aos="fade-up">
          <CountUp prefix={'+'} end={20} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <Number ref={countUpRef}></Number>
              </VisibilitySensor>
            )}
          </CountUp>
          <Title>
            <FormattedMessage id="about.textA" />
          </Title>
        </NumberContainer>
        <NumberContainer data-aos="fade-up">
          <CountUp prefix={'+'} end={250} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <Number ref={countUpRef}></Number>
              </VisibilitySensor>
            )}
          </CountUp>
          <Title>
            <FormattedMessage id="about.textB" />
          </Title>
        </NumberContainer>
        <NumberContainer data-aos="fade-up">
          <CountUp prefix={'+'} end={900} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <Number ref={countUpRef}></Number>
              </VisibilitySensor>
            )}
          </CountUp>
          <Title>
            <FormattedMessage id="about.textC" />
          </Title>
        </NumberContainer>
        <NumberContainer data-aos="fade-up">
          <CountUp prefix={'+'} end={4} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <Number ref={countUpRef}></Number>
              </VisibilitySensor>
            )}
          </CountUp>
          <Title>
            <FormattedMessage id="about.textD" />
          </Title>
        </NumberContainer>
      </ContentWrapper>
    </ServicesContainer>
  );
};

export default About;
