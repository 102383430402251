import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { animateScroll as scroll } from 'react-scroll';

import section from '../../images/hero/wave-12.svg';
// import app1 from '../../images/hero/undraw_Goals_re_lu76.svg';
import app1 from '../../images/hero/hero2.png';
import videoBackground from '../../videos/video.mp4';
import AutoplayVideo from '../General/AutoplayVideo';

export default function HeroSection() {
  const intl = useIntl();
  const history = useHistory();
  const [hoverCalendar, setHoverCalendar] = useState(false);
  const [hoverVideo, setHoverVideo] = useState(false);

  const onHoverCalendar = () => {
    setHoverCalendar(!hoverCalendar);
  };

  const onHoverVideo = () => {
    setHoverVideo(!hoverVideo);
  };

  return (
    <HeroContainer id="home">
      <AutoplayVideo video={videoBackground} />
      <HeroBg>
        <ImageBg src={section} alt={'hero'} />
      </HeroBg>
      <InfoWrapper>
        <InfoRow>
          <Column1>
            <HeroContent>
              <HeroH1 data-aos="fade-left" data-aos-duration="1000">
                {intl.locale === 'en' ? (
                  <>
                    <span>
                      <FormattedMessage id="hero.titleA" />
                    </span>{' '}
                    <FormattedMessage id="hero.titleB" />
                  </>
                ) : (
                  <>
                    <FormattedMessage id="hero.titleB" />{' '}
                    <span>
                      <FormattedMessage id="hero.titleA" />
                    </span>
                  </>
                )}
              </HeroH1>
              <HeroP data-aos="fade-left" data-aos-duration="2000">
                <FormattedMessage id="hero.subtitle" />
              </HeroP>
              <ButtonsWrapper data-aos="fade-up" data-aos-duration="1000">
                <HeroBtnWrapper>
                  <CalendarButton
                    onClick={() => {
                      history.push('/calendar');
                      scroll.scrollToTop();
                    }}
                    onMouseEnter={onHoverCalendar}
                    onMouseLeave={onHoverCalendar}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact={true}
                    offset={-80}
                  >
                    <FormattedMessage id="hero.buttonA" />{' '}
                    {hoverCalendar ? <ArrowForward /> : <ArrowRight />}
                  </CalendarButton>
                </HeroBtnWrapper>
                <HeroBtnWrapper>
                  <VideoButton
                    href="https://storage.googleapis.com/nupav_public/brochure.pdf"
                    target={'_blank'}
                    rel="noopener noreferrer"
                    onMouseEnter={onHoverVideo}
                    onMouseLeave={onHoverVideo}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact={true}
                    offset={-80}
                  >
                    <FormattedMessage id="hero.buttonB" />{' '}
                    {hoverVideo ? <ArrowForward /> : <ArrowRight />}
                  </VideoButton>
                </HeroBtnWrapper>
              </ButtonsWrapper>
            </HeroContent>
          </Column1>
          <Column2>
            <ImgWrap data-aos="fade-left" data-aos-duration="1000">
              <Img src={app1} alt={'app-2'} />
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
    </HeroContainer>
  );
}

const HeroContainer = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  height: 800px;
  position: relative;
  z-index: 1;
`;

const HeroBg = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
`;

const ImageBg = styled.img`
  width: 110%;
  height: 100%;
  margin: 0 -5px -8px -5px;
  object-fit: cover;
`;

const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  /* //height: 800px; */
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  /* //padding: 0 24px; */
  justify-content: center;
  /* //background: red; */
`;

const InfoRow = styled.div`
  display: grid;
  /* grid-auto-columns: minmax(auto, 1fr); */
  grid-template-columns: 0.6fr 1fr;
  align-items: center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col1 col1' 'col2 col2';
    //margin-top: -100px;
  }
`;

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  /* //background: black; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ImgWrap = styled.div`
  /* max-width: 555px; */
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 0 10px 0;
  -o-bject-fit: cover;
  object-fit: cover;
`;

const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  /* //position: absolute; */
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const HeroH1 = styled.h1`
  color: rgba(77, 80, 85, 1);
  font-size: 48px;
  text-align: left;
  max-width: 600px;

  > span {
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const HeroP = styled.p`
  margin-top: 24px;
  color: rgba(77, 80, 85, 1);
  font-size: 24px;
  text-align: left;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  //background: black;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`;

const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: red;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
    align-items: flex-start;
  }
`;

const CalendarButton = styled(Link)`
  border-radius: 50px;
  background: #192685;
  white-space: nowrap;
  padding: 14px 48px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(77, 80, 85, 1);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 28px;
    margin-right: 5px;
    margin-top: 32px;
  }
`;

const VideoButton = styled.a`
  border-radius: 50px;
  background: transparent;
  white-space: nowrap;
  padding: 14px 48px;
  color: #7b83b9;
  font-size: 16px;
  outline: none;
  border: 2px;
  border: #ff8000;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: rgba(77, 80, 85, 1);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 28px;
    margin-right: 5px;
  }
`;
