import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import Footer from '../components/Footer';
import ProductModal from '../components/ProductModal/ProductModal';

import section from '../images/hero/wave-12.svg';
import topWave from '../images/footer/wave-3.svg';
import bg from '../images/product/productBG.png';
import informes from '../images/product/informes.png';
import calendar from '../images/product/calendar.png';
import vista360 from '../images/product/vista360.png';
import cad from '../images/product/3D.png';
import visorDoc from '../images/product/visorDoc.png';
import tableros from '../images/product/tableros.png';
import permisoAccesos from '../images/product/permisoAccesos.png';
import documental from '../images/product/documental.png';
import whiteboard from '../images/product/whiteboard.png';
import licitaciones from '../images/product/licitaciones.png';
import permisos from '../images/product/permisos.png';
import avancefisico from '../images/product/avancefisico.png';
import minutas from '../images/product/minutas.png';
import hallazgos from '../images/product/hallazgos.png';
import camera from '../images/product/camera.png';
import rcd from '../images/product/rcd.png';
import financiero from '../images/product/financiero.png';
import adquisiciones from '../images/product/adquisiciones.png';
import rrhh from '../images/product/rrhh.png';
import iot from '../images/product/iot.png';
import activos from '../images/product/activos.png';
import testing from '../images/product/testing.png';

const Card = ({ title, modules, setModal }) => (
  <CardContainer data-aos="fade-up">
    <span>{title}</span>
    <div>
      {modules.map((x, index) => (
        <div onClick={() => setModal({ open: true, module: x })}>
          <span>{x.name}</span>
        </div>
      ))}
    </div>
  </CardContainer>
);

const MoreCard = ({ title, description, image }) => (
  <MoreContainer data-aos="fade-up">
    <div>
      <div>
        <div>
          <img src={image} alt="" />
        </div>
        <div>
          <span>{title}</span>
          <span>{description}</span>
        </div>
      </div>
    </div>
  </MoreContainer>
);

export default function Product() {
  const intl = useIntl();

  const [modal, setModal] = useState(false);

  return (
    <>
      <Container>
        <div>
          <div>
            <img src={bg} alt="bg" />
          </div>
          <div />
          <div>
            <h1 data-aos="fade-left" data-aos-duration="1000">
              <FormattedMessage id="product.title" />
            </h1>
            <p data-aos="fade-left" data-aos-duration="2000">
              <FormattedMessage id="product.subtitle" />.
            </p>
          </div>
          <div>
            <img src={section} alt="wave" />
          </div>
        </div>
        <div>
          <Card
            setModal={(x) => setModal(x)}
            title={intl.formatMessage({ id: 'product.sectionA' })}
            modules={[
              {
                name: intl.formatMessage({ id: 'product.sectionA.itemA' }),
                to: 'boards',
                image: tableros,
                description: [
                  intl.formatMessage({ id: 'product.sectionA.descA1' }),
                  intl.formatMessage({ id: 'product.sectionA.descA2' }),
                  intl.formatMessage({ id: 'product.sectionA.descA3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionA.itemB' }),
                to: 'documents',
                image: documental,
                description: [
                  intl.formatMessage({ id: 'product.sectionA.descB1' }),
                  intl.formatMessage({ id: 'product.sectionA.descB2' }),
                  intl.formatMessage({ id: 'product.sectionA.descB3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionA.itemC' }),
                to: 'whiteboard',
                image: whiteboard,
                description: [
                  intl.formatMessage({ id: 'product.sectionA.descC1' }),
                  intl.formatMessage({ id: 'product.sectionA.descC2' }),
                  intl.formatMessage({ id: 'product.sectionA.descC3' }),
                ],
              },
            ]}
          />
          <Card
            setModal={(x) => setModal(x)}
            title={intl.formatMessage({ id: 'product.sectionB' })}
            modules={[
              {
                name: intl.formatMessage({ id: 'product.sectionB.itemA' }),
                to: 'tenders',
                image: licitaciones,
                description: [
                  intl.formatMessage({ id: 'product.sectionB.descA1' }),
                  intl.formatMessage({ id: 'product.sectionB.descA2' }),
                  intl.formatMessage({ id: 'product.sectionB.descA3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionB.itemB' }),
                to: 'permits',
                image: permisos,
                description: [
                  intl.formatMessage({ id: 'product.sectionB.descB1' }),
                  intl.formatMessage({ id: 'product.sectionB.descB2' }),
                  intl.formatMessage({ id: 'product.sectionB.descB3' }),
                  intl.formatMessage({ id: 'product.sectionB.descB4' }),
                ],
              },
            ]}
          />
          <Card
            setModal={(x) => setModal(x)}
            title={intl.formatMessage({ id: 'product.sectionC' })}
            modules={[
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemA' }),
                to: 'physicaladvancement',
                image: avancefisico,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descA1' }),
                  intl.formatMessage({ id: 'product.sectionC.descA2' }),
                  intl.formatMessage({ id: 'product.sectionC.descA3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemB' }),
                to: 'financialbreaktrough',
                image: financiero,
                description: [intl.formatMessage({ id: 'product.sectionC.descB1' })],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemC' }),
                to: 'minutes',
                image: minutas,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descC1' }),
                  intl.formatMessage({ id: 'product.sectionC.descC2' }),
                  intl.formatMessage({ id: 'product.sectionC.descC3' }),
                  intl.formatMessage({ id: 'product.sectionC.descC4' }),
                  intl.formatMessage({ id: 'product.sectionC.descC5' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemD' }),
                to: 'findings',
                image: hallazgos,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descD1' }),
                  intl.formatMessage({ id: 'product.sectionC.descD2' }),
                  intl.formatMessage({ id: 'product.sectionC.descD3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemE' }),
                to: 'virtualsite',
                image: vista360,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descE1' }),
                  intl.formatMessage({ id: 'product.sectionC.descE2' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemF' }),
                to: 'camera',
                image: camera,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descF1' }),
                  intl.formatMessage({ id: 'product.sectionC.descF2' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemG' }),
                to: 'wastemanagement',
                image: rcd,
                description: [intl.formatMessage({ id: 'product.sectionC.descG1' })],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemH' }),
                to: 'acquisitions',
                image: adquisiciones,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descH1' }),
                  intl.formatMessage({ id: 'product.sectionC.descH2' }),
                  intl.formatMessage({ id: 'product.sectionC.descH3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemI' }),
                to: 'suppliers',
                image: testing,
                description: [intl.formatMessage({ id: 'product.sectionC.descI1' })],
                build: true,
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemJ' }),
                to: 'rrhh',
                image: rrhh,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descJ1' }),
                  intl.formatMessage({ id: 'product.sectionC.descJ2' }),
                  intl.formatMessage({ id: 'product.sectionC.descJ3' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemK' }),
                to: 'iot',
                image: iot,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descK1' }),
                  intl.formatMessage({ id: 'product.sectionC.descK2' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemL' }),
                to: 'assets',
                image: activos,
                description: [
                  intl.formatMessage({ id: 'product.sectionC.descL1' }),
                  intl.formatMessage({ id: 'product.sectionC.descL2' }),
                ],
              },
              {
                name: intl.formatMessage({ id: 'product.sectionC.itemM' }),
                to: 'security',
                image: testing,
                description: [intl.formatMessage({ id: 'product.sectionC.descM1' })],
                build: true,
              },
            ]}
          />
          <Card
            setModal={(x) => setModal(x)}
            title={intl.formatMessage({ id: 'product.sectionD' })}
            modules={[
              {
                name: intl.formatMessage({ id: 'product.sectionD.itemA' }),
                to: 'quality',
                image: testing,
                description: [intl.formatMessage({ id: 'product.sectionD.descA1' })],
                build: true,
              },
              {
                name: intl.formatMessage({ id: 'product.sectionD.itemB' }),
                to: 'inspections',
                image: testing,
                description: [intl.formatMessage({ id: 'product.sectionD.descB1' })],
                build: true,
              },
            ]}
          />
        </div>
        <div>
          <div>
            <img src={topWave} alt="topwave" />
          </div>
          <div>
            <h1>
              <FormattedMessage id="product.features.title" />
            </h1>
          </div>
          <MoreCard
            title={intl.formatMessage({ id: 'product.features.moreTitleA' })}
            image={informes}
            description={intl.formatMessage({ id: 'product.features.moreDescA' })}
          />
          <MoreCard
            title={intl.formatMessage({ id: 'product.features.moreTitleB' })}
            image={calendar}
            description={intl.formatMessage({ id: 'product.features.moreDescB' })}
          />
          <MoreCard
            title={intl.formatMessage({ id: 'product.features.moreTitleC' })}
            image={vista360}
            description={intl.formatMessage({ id: 'product.features.moreDescC' })}
          />
          <MoreCard
            title={intl.formatMessage({ id: 'product.features.moreTitleD' })}
            image={cad}
            description={intl.formatMessage({ id: 'product.features.moreDescD' })}
          />
          <MoreCard
            title={intl.formatMessage({ id: 'product.features.moreTitleE' })}
            image={visorDoc}
            description={intl.formatMessage({ id: 'product.features.moreDescE' })}
          />
          <MoreCard
            title={intl.formatMessage({ id: 'product.features.moreTitleF' })}
            image={permisoAccesos}
            description={intl.formatMessage({ id: 'product.features.moreDescF' })}
          />
        </div>
      </Container>
      <Footer />
      {modal.open && (
        <ProductModal open={modal.open} onClose={() => setModal(false)} module={modal.module} />
      )}
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #192685;
  width: 100%;

  > div:nth-child(1) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 150px 40px 250px 40px;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: 1280px) {
      padding: 150px 40px 200px 40px;
    }

    @media screen and (max-width: 800px) {
      padding: 150px 40px 150px 40px;
    }

    > div:nth-child(1) {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 0;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > div:nth-child(2) {
      position: absolute;
      top: -100px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.9);
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      max-width: 1024px;
      z-index: 2;
      > h1 {
        color: rgba(77, 80, 85, 1);
        font-size: 48px;

        @media screen and (max-width: 480px) {
          font-size: 32px;
        }
      }

      > p {
        margin-top: 24px;
        color: rgba(77, 80, 85, 1);
        font-size: 24px;

        @media screen and (max-width: 480px) {
          font-size: 18px;
        }
      }
    }
    > div:nth-child(4) {
      position: absolute;
      bottom: -8px;
      left: -5px;
      right: -5px;
      z-index: 2;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    z-index: 2;
    background-color: #192685;
    width: 100%;

    @media screen and (max-width: 500px) {
      padding: 20px 15px;
    }
  }

  > div:nth-child(3) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: white;
    width: 100%;
    overflow: hidden;

    > div:nth-child(1) {
      position: absolute;
      top: -8px;
      left: -5px;
      right: -5px;
      z-index: 2;
      /* background-color: white; */
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 350px 40px 0 40px;
      max-width: 1024px;

      @media screen and (max-width: 1280px) {
        padding: 250px 40px 0 40px;
      }

      @media screen and (max-width: 1024px) {
        padding: 200px 40px 0 40px;
      }

      > h1 {
        text-align: center;
        color: rgba(77, 80, 85, 1);
        margin-bottom: 100px;
      }
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  max-width: 1024px;

  > span {
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 20px;
  }

  > div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    /* max-width: 1024px; */

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: 1fr 1fr;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin-bottom: 10px;
      border-radius: 4px;
      padding: 10px;
      min-height: 61px;
      cursor: pointer;

      > span {
        text-align: center;
        font-size: 18px;
        color: dimgray;
        font-weight: 400;

        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
      }

      :hover {
        transition: all 0.2s ease-in-out;
        scale: 1.03;
      }
    }
  }
`;

const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  padding: 0 40px;
  margin-bottom: 70px;

  > div {
    display: flex;
    width: 100%;

    > div {
      display: flex;
      width: 100%;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        > img {
          /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
          max-width: 400px;
          width: 100%;
          height: 100%;
          object-fit: contain;
          margin-bottom: 20px;

          @media screen and (max-width: 800px) {
            max-width: 100%;
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        padding: 15px;
        width: 100%;

        @media screen and (max-width: 800px) {
          margin-left: 0px;
          padding: 0px;
        }

        > span:nth-child(1) {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
          color: rgba(77, 80, 85, 1);
        }
        > span:nth-child(2) {
          font-size: 18px;
          /* color: white; */
          font-weight: 300;
        }
      }
    }
  }
`;
