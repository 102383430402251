import React from 'react';
import Carousel from 'react-elastic-carousel';
import { FormattedMessage, useIntl } from 'react-intl';

import { testimoniosData } from './Data';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesH3,
  ServicesP,
} from './TestimoniosElements';

const innerWidth = window.innerWidth;

const Testimonials = () => {
  const intl = useIntl();

  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
  ];

  return (
    <ServicesContainer id={'testimonials'}>
      <ServicesH1>
        <FormattedMessage id="testimonios.title" />
      </ServicesH1>
      <Carousel
        breakPoints={breakpoints}
        enableAutoPlay={false}
        showArrows={innerWidth < 500 ? false : true}
      >
        {testimoniosData.map((item) => (
          <ServicesWrapper key={item.id}>
            <ServicesCard>
              <ServicesIcon src={item.image} />
              <ServicesH2>{item.title}</ServicesH2>
              <ServicesH3>{intl.locale === 'es' ? item.job : item.jobEn}</ServicesH3>
              <ServicesP>"{intl.locale === 'es' ? item.text : item.textEn}"</ServicesP>
            </ServicesCard>
          </ServicesWrapper>
        ))}
      </Carousel>
    </ServicesContainer>
  );
};

export default Testimonials;
