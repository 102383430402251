import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Aos from 'aos';

import './App.css';
import Home from './pages/Home';
import Politica from './pages/Politica';
import Calendar from './pages/Calendar';
import esMessages from './locales/es.json';
import enMessages from './locales/en.json';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar';
import Access from './pages/Access';
import Solutions from './pages/Solutions';
import Product from './pages/Product';
import Pricing from './pages/Pricing';

export default function App() {
  const lang = navigator.language || navigator.userLangauge;
  const langLocale = lang.includes('en') ? 'en' : 'es';
  const [locale, setLocale] = useState(langLocale);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  window.Intercom('shutdown'); //hacemos esto para resetear el cache de intercom cuando viene desde cloud.nupav.com
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'b7b3qbqu',
    custom_launcher_selector: '#support',
    language_override: locale,
  });

  useEffect(() => {
    Aos.init({ duration: 1500 });
    Aos.refresh();
  }, []);

  return (
    <IntlProvider messages={locale === 'es' ? esMessages : enMessages} key={locale} locale={locale}>
      <Router>
        <Navbar toggle={toggle} locale={locale} setLocale={setLocale} />
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Switch>
          <Route path="/" exact>
            <Home locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
          <Route path="/product" exact>
            <Product locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
          <Route path="/solutions" exact>
            <Solutions locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
          <Route path="/pricing" exact>
            <Pricing locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
          <Route path="/access" exact>
            <Access locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
          <Route path="/calendar" exact>
            <Calendar locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
          <Route path="/politica-de-privacidad" exact>
            <Politica locale={locale} setLocale={(x) => setLocale(x)} />
          </Route>
        </Switch>
      </Router>
    </IntlProvider>
  );
}
