import styled from 'styled-components';

export const ServicesContainer = styled.div`
  height: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: green; */
  background: #192685;

  @media screen and (max-width: 1600px) {
    height: 900px;
  }

  @media screen and (max-width: 1024px) {
    height: 600px;
  }

  @media screen and (max-width: 768px) {
    height: 1000px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

export const InfoBg2 = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #192685 1%, #ffffff 1%);
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 64px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1024px;
  width: 100%;
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin: 0 24px 0 24px;
`;

export const Number = styled.span`
  font-size: 4.5rem;
  color: white;
  margin-bottom: 10px;
  text-align: center;
`;

export const Title = styled.span`
  font-size: 1.3rem;
  color: white;
  margin-bottom: 64px;
  text-align: center;
  max-width: 150px;
`;
